import { useEffect, } from "react";
import HeroSection from "../components/hero";
import MobileHeader from "../components/mobile/header";
import WhoAreWe from "../components/whoarewe";
import OurServices from "../components/services";
import OurPartners from "../components/ourpartners";
import CustomerReview from "../components/review";
import ContactUs from "../components/contact";


function Home() {
  
  useEffect(() => {
    
    window.scrollTo({ top: 0 });
  });

  return (
    <div className="main">
      <div id="header-section">
        <HeroSection />
        <MobileHeader />
      </div>
      <WhoAreWe />
      <OurServices />
      <CustomerReview />
      <OurPartners />
      <ContactUs />
    </div>
  );
}

export default Home;
