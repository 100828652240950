import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { MdArrowForward } from "react-icons/md";
import "../sass/nin.scss";
import axios from "axios";
//  import { ToastContainer, toast } from "react-toastify";
//  import "react-toastify/dist/ReactToastify.css";



const SendOTP = () => {
  const { phoneNumber, trackingId, authData, setAccountNumber } = useContext(AuthContext);
  const [otp, setOtp] = useState("");
  const [pageLoading, setPageLoading] = useState(false);

  const navigate = useNavigate();

  const accData = {
    phoneNumber,
    otp,
    trackingId,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPageLoading(true);

    try {
    
      const res = await axios.post(
        "https://rockbank-server.vercel.app/confirm-otp",
        accData
      );
      console.log(res.data);
      if (
        res.status === 200 &&
        res.data.data.accountGenerationStatus === "Pending"
      ) {
        setAccountNumber(phoneNumber) //the account number will be set with the account to generate from the API
        alert(res.data.message);
        //console.log("authData", authData)
        
        if (authData.user.role === "Investor") navigate("/investor");
        // if (authData.user.role === "Financial-Planner")
        //   navigate("/financial-planner");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <div className="main-container">
      <div className="register-content">
        <div className="reg-form-section">
          <form className="form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="otp" className="form-label">
                    Enter OTP
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="otp"
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="btn-section">
                <button type="submit" className="btn-submit">
                  {pageLoading ? (
                    <RotatingLines
                      visible={true}
                      height="30"
                      width="30"
                      color="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    <span>
                      Submit OTP <MdArrowForward />
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>

          {/* <ToastContainer /> */}
        </div>
      </div>
    </div>
  );
};

export default SendOTP;
