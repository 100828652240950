// src/App.js
import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

import { AuthProvider, AuthContext } from "./context/AuthContext";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Navbar from "./components/navbar";
import Faqs from "./pages/faq";
import Retailer from "./pages/retail";
import Footer from "./components/footer";
import InvestorDashboard from "./pages/investor";
import SendNIN from "./pages/SendNIN";
import SendOTP from "./pages/SendOTP";
import SendBVN from "./pages/SendBVN";
import Investments from "./pages/Investment";
import SignupReferral from "./pages/financialPlanner"

const PrivateRoute = ({ children, roles }) => {
  const { authData } = React.useContext(AuthContext);
  if (!authData.isAuthenticated) {
    return <Navigate to="/login" />;
  }
  if (roles && !roles.includes(authData.user.role)) {
    return <Navigate to="/" />;
  }
  return children;
};

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route index element={<Home />} />
        <Route path="/register" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/retail" element={<Retailer />} />
        <Route path="/referral" element={<SignupReferral/>}/>

        {/* Protected Routes */}
        <Route
          path="/admin"
          element={
            <PrivateRoute roles={["Admin"]}>
              <div>Admin Dashboard</div>
            </PrivateRoute>
          }
        />

        <Route
          path="/financial-planner"
          element={
            <PrivateRoute roles={["Financial-Planner", "Admin"]}>
              <div>Financial Staff Dashboard</div>
            </PrivateRoute>
          }
        />

        <Route
          path="confirm-nin"
          element={
            // <PrivateRoute roles={["Investor", "Financial-Planner", "Admin"]}>
            <SendNIN />
            // </PrivateRoute>
          }
        />

        <Route
          path="confirm-otp"
          element={
            <PrivateRoute roles={["Investor", "Financial-Planner", "Admin"]}>
            <SendOTP />
            </PrivateRoute>
          }
        />

        <Route
          path="confirm-bvn"
          element={
            <PrivateRoute roles={["Investor", "Financial-Planner", "Admin"]}>
            <SendBVN />
            </PrivateRoute>
          }
        />

        <Route
          path="/investor"
          element={
            <PrivateRoute roles={["Investor", "Financial-Planner", "Admin"]}>
              <InvestorDashboard />
            </PrivateRoute>
          }
        />

        <Route
          path="/investments"
          element={
            <PrivateRoute roles={["Investor", "Financial-Planner", "Admin"]}>
              <Investments />
            </PrivateRoute>
          }
        ></Route>
      </Route>
    )
  );
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
};

const Root = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

export default App;
