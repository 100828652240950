import { Slide } from "react-slideshow-image";
import "../sass/review.scss";
import "react-slideshow-image/dist/styles.css";

function CustomerReview() {
  return (
    <div className="review-main" id="reviews-section">
      <div className="container">
        <div className="reviews-header">
          <h3>Hear What Our Customers Have to Say</h3>
        </div>
        <div className="reviews">
          <Slide>
            <div className="each-slide-effect">
              <div>
                <p>
                  My financial life was in a real mess till met this team. They
                  restructured my finances for me and helped me gain financial
                  freedom over a defined period of time. I have never met
                  professionals who truly care about me like they do. Thank you
                  Team.
                </p>
                <h4>-Josephine L.</h4>
              </div>
            </div>
            <div className="each-slide-effect">
              <div>
                <p>
                  “RockBank has helped me grow my savings like never before, I
                  couldn't be happier!”
                </p>
                <h4>-Sarah M., New York, NY</h4>
              </div>
            </div>
            <div className="each-slide-effect">
              <div>
                <p>
                  I love the team, they are very professional and respectful.
                  Despite my low level of financial education, they carefully
                  helped me rebuild my finances.
                </p>
                <h4>-Rowland E.</h4>
              </div>
            </div>
            <div className="each-slide-effect">
              <div>
                <p>
                  I have been in business for many years but couldnt save much
                  or even grow my income. This team helped me plan my finances
                  in such a way that my savings is now very significant and
                  compounding. I cant thank them enough, I now have sufficient
                  funds for my current needs and my future plans. They are
                  really good.
                </p>
                <h4>-Benson A.</h4>
              </div>
            </div>
            <div className="each-slide-effect">
              <div>
                <p>
                  “I never thought saving money could be so easy and rewarding
                  until I started using RockBank's platform.”
                </p>
                <h4>—John D., Los Angeles, CA</h4>
              </div>
            </div>
            <div className="each-slide-effect">
              <div>
                <p>
                  I started a company a few years ago but despite my revenue I
                  kept being broke. I spoke to the team and they helped me
                  reorganise and restructure my finances. Now I have a growing
                  savings after my expenses and my company has gained financial
                  stability. Their Professionalism and Integrity is topnotch.
                </p>
                <h4>-Bode N.</h4>
              </div>
            </div>
            <div className="each-slide-effect">
              <div>
                <p>
                  I have never experienced savings in such simplicity ever
                  before. Their services are great. Thanks to this team of
                  professional financial planners, they truly do an excellent
                  job
                </p>
                <h4>-Eucharia J.</h4>
              </div>
            </div>
            <div className="each-slide-effect">
              <div>
                <p>
                  “RockBank has exceeded all my expectations, I highly recommend
                  them to anyone looking to grow their wealth.”
                </p>
                <h4>—Emily K., Chicago, IL</h4>
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </div>
  );
}

export default CustomerReview;
