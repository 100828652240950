//import { Link } from "react-router-dom";
import image from "../assets/images/sarah.png";
import "../sass/whoarewe.scss";

function WhoAreWe() {
  return (
    <div className=" whoarewe-main" id="whoarewe-section">
      <div className="container">
        <div className="hero-main">
          <div className="whoarewe-header">
            <h2>Who We Are?</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="image-section">
                <img src={image} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-section">
                <p>
                  At RockBank, we understand the importance of building a secure
                  financial foundation for individuals and businesses alike.
                  With our innovative savings platform, you can earn
                  significantly better returns in multiple currencies, ensuring
                  your wealth grows sustainably over time to edge it against
                  inflation. Our user-friendly interface and cutting-edge
                  technology makes managing your finances easy and convenient.
                  Whether you're saving for a rainy day or planning for the
                  future, RockBank is here to help you achieve your financial
                  goals. Join the RockBank community today and start taking
                  control of your financial future. With our commitment to
                  security and transparency, you can trust that your savings are
                  in good hands. Don't wait, start building transgenerational
                  wealth with RockBank now.
                </p>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default WhoAreWe;
