import { useEffect } from "react";
import "../sass/retail.scss";

//import Login from "../components/login";
//import Register from "../components/register";

function Retailer() {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="retail-main">
      <div className="container">
        {/* <Login /> */}
        <div className="comming-soon">
          
          <h1>Coming Soon!!</h1>
          <p>This page will be available soon</p>
        </div>
      </div>
    </div>
  );
}

export default Retailer;
