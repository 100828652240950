import { useEffect, useState } from "react";
import "../sass/contact.scss";
import emailjs from "emailjs-com"


function ContactUs() {
  const TEMPLATE_ID = "template_d1dpeuq";
  const SERVICE_ID = "service_rtk0udr";
  const PUBLIC_KEY = "t0HHpTQ_YbywUNp2K";

  const [formData, setFormData] = useState({
    from_name: "",
    email: "",
    message: "",
  });

  //const [name]

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    //console.log(formData)
  };

    const handleSubmit = (e) => {
      e.preventDefault();

      emailjs
        .send(
          SERVICE_ID, // replace with your EmailJS Service ID
          TEMPLATE_ID, // replace with your EmailJS Template ID
          formData, // form data to fill placeholders in template
          PUBLIC_KEY // replace with your EmailJS User ID
        )
        .then(() => {
          alert("Message sent successfully!");
          setFormData({name: "", email: "", message: "" });
        })
        .catch((error) => {
          console.error("EmailJS error:", error);
          alert("Failed to send message. Please try again later.");
        });
    };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="contact-main" id="contact-section">
      <div className="container">
        <div className="contact-title">
          <span>Contact us for more enquiry</span>
        </div>
        <div className="form-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="nameInput" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="nameInput"
                name="from_name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                name="email"
                aria-describedby="emailHelp"
                value={formData.name}
                onChange={handleChange}
              />
              <div id="emailHelp" className="form-text">
                We will never share your email with anyone else.
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="floatingTextarea2" className="form-label">
                Your questions
              </label>
              <textarea
                className="form-control"
                placeholder=""
                id="floatingTextarea2"
                name="message"
                value={formData.name}
                onChange={handleChange}
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;

// import React, { useState } from "react";
// import emailjs from "emailjs-com";

// const ContactUs = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     emailjs
//       .send(
//         "YOUR_SERVICE_ID", // replace with your EmailJS Service ID
//         "YOUR_TEMPLATE_ID", // replace with your EmailJS Template ID
//         formData, // form data to fill placeholders in template
//         "YOUR_USER_ID" // replace with your EmailJS User ID
//       )
//       .then(() => {
//         alert("Message sent successfully!");
//         setFormData({ name: "", email: "", message: "" });
//       })
//       .catch((error) => {
//         console.error("EmailJS error:", error);
//         alert("Failed to send message. Please try again later.");
//       });
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <label>
//         Name:
//         <input
//           type="text"
//           name="name"
//           value={formData.name}
//           onChange={handleChange}
//           required
//         />
//       </label>
//       <br />
//       <label>
//         Email:
//         <input
//           type="email"
//           name="email"
//           value={formData.email}
//           onChange={handleChange}
//           required
//         />
//       </label>
//       <br />
//       <label>
//         Message:
//         <textarea
//           name="message"
//           value={formData.message}
//           onChange={handleChange}
//           required
//         />
//       </label>
//       <br />
//       <button type="submit">Send</button>
//     </form>
//   );
// };

// export default ContactUs;

