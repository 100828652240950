import { useInView } from "react-intersection-observer";
import "../sass/services.scss";

function OurServices() {
  const { ref: myRef, inView: servicesSectionIsVisible } = useInView();

  return (
    <div className=" services-main" id="services-section" ref={myRef}>
      <div className="container">
        <div className="services-header">
          
        </div>
        <div className={`${servicesSectionIsVisible ? "services" : ""}`}>
          <div className="row">
            <div className="col-md-6 flip1">
              <div className="service1">
                <h3>Securing Your Financial Future With RockBank </h3>
                <p>
                  At RockBank, we understand the importance of building and
                  preserving wealth for future generations. Our platform offers
                  competitive high interest rates on multiple currencies,
                  allowing you to maximize your savings potential. With advanced
                  security measures in place, you can trust RockBank to
                  safeguard your financial future and build transgenerational
                  wealth. Easily manage your funds online, track your savings
                  progress, and take advantage of our customizable savings plans
                  to meet your financial goals. Join RockBank today and start
                  building a secure financial future for yourself and your loved
                  ones. Take advantage of our high interest rates and innovative
                  savings tools to create a legacy of wealth that will last for
                  generations.
                </p>
              </div>
            </div>
            <div className="col-md-6 flip2">
              <div className="service2">
                <h3>Explore Our Range of Financial Services</h3>
                <ul>
                  <li>High interest saving account</li>
                  <li>Multi-currency savings options</li>
                  <li>Expert financail advice</li>
                  <li>Business financing solutions</li>
                  <li>Retirement planning services</li>
                  <li>Estate planning</li>
                  <li>Personalized wealth build plans</li>
                  <li>Tax planning</li>
                  <li>Financial goal setting</li>
                  <li>Risk management advisory</li>
                  <li>Financial education and resources</li>
                  <li>Online account access and management</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="whyrockbank">
          <h2>Why Choose Us?</h2>
          <div className="row">
            <div className="col-md-4">
              <p>
                <b>Customer-Centric Approach:</b> We prioritize the needs and
                satisfaction of our customers above all else.
              </p>
            </div>
            <div className="col-md-4">
              <p>
                <b>Innovation:</b> We leverage technology and innovation to
                deliver efficient and convenient financial solutions.
              </p>
            </div>
            <div className="col-md-4">
              <p>
                <b> Trustworthiness:</b> Our commitment to transparency and
                integrity ensures that you can trust us with your financial
                needs.
              </p>
            </div>
          </div>
          <div className="oveview">
            <h2>Industry Overview</h2>
            <p>
              The financial industry has faced significant challenges in recent
              years, leading to increased stress and anxiety for individuals
              managing their personal and business finances. Many people feel
              overwhelmed and lack control over their financial decisions,
              largely due to the complexity and uncertainty that arises from
              limited financial literacy. As a result, numerous individuals have
              been hesitant to venture beyond basic banking services, feeling
              uncertain about navigating the vast array of financial options
              available. In response to this need, we have developed a
              comprehensive financial solution designed to provide relief and
              peace of mind. As a neobank driven by a customer-centric approach,
              we prioritize empathy and understanding, recognizing that our
              clients are individuals with unique financial goals and
              aspirations, not just account holders. We are committed to being a
              trusted partner throughout every stage of our clients' financial
              journeys, leveraging our expertise to deliver exceptional service
              and support. By doing so, we aim to empower individuals to take
              control of their finances and achieve long-term financial
              well-being."
            </p>
          </div>

          <div className="oveview" style={{textAlign:"center", marginTop:"70px"}}>
            <h2>Learn About RockBank</h2>
            <p>
              At RockBank, we are dedicated to providing a secure and innovative
              multi-currency high interest savings platform for individuals and
              businesses. Our mission is to help our clients build sustainable
              wealth that could be passed down through generations. Our team of
              finance experts are committed to providing personalized solutions
              and exceptional customer service to help you achieve your
              financial goals. We believe in transparency, integrity, and trust
              in all aspects of our business. Join us on this journey to
              financial success and let RockBank be your partner in building a
              secure financial future for yourself and your loved ones. Start
              building your wealth with confidence
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
