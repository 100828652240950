import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../sass/userdashboard.scss";
import {
  RiMoneyDollarCircleFill,
  RiMoneyPoundCircleFill,
  RiMoneyEuroCircleFill,
  RiCopperCoinFill,
} from "react-icons/ri";
import { MdCircleNotifications, MdAccessTimeFilled } from "react-icons/md";
import { AuthContext } from "../context/AuthContext";
import background2 from "../assets/videos/background2.mp4";
import Modal from "../components/modal"

function InvestorDashboard() {
  const { authData, accountNumber } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showCreateAccountButton, setShowAccountButton] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    checkAccountCreationStatus();
  });

  function checkAccountCreationStatus() {
    if (authData.accountNumber === "") {
      setShowAccountButton(false);
    }
  }

  return (
    <div className="background-video-container">
      <video autoPlay loop muted playsInline className="background-video">
        <source src={background2} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content-overlay">
        <div className="containe">
          <div className="averta-section">
            {authData.isAuthenticated ? (
              <p style={{ fontSize: 24, color: "#000" }}>
                Welcome, {authData.user.name}
              </p>
            ) : (
              ""
            )}
            <div className="notifications">
              <MdCircleNotifications color="#ffffff" />
            </div>
          </div>
          <div className="dashboard-body">
            {showCreateAccountButton ? (
              <div className="btn-creat-acct-section">
                <button className="btn-createAccount" onClick={openModal}>
                  Create Wallet Account
                </button>
              </div>
            ) : (
              <span style={{ color: "#fff" }}>
                Account Number: {accountNumber}
              </span>
            )}
            <div className="scroll-section">
              <div className="row">
                <div className="col-md-12">
                  <div className="dashboard-quick-links">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="user-balance">
                          <Link to="/investor">
                            <div
                              className="coins"
                              style={{ color: "#00009f", fontSize: "34px" }}
                            >
                              <RiCopperCoinFill />
                            </div>
                            <span
                              style={{ color: "#00009f", marginTop: "15px" }}
                            >
                              Fund Wallet
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="user-balance">
                          <Link>
                            <div
                              className="coins"
                              style={{ color: "#00009f", fontSize: "34px" }}
                            >
                              <MdAccessTimeFilled />
                            </div>
                            <p style={{ color: "#00009f", marginTop: "15px" }}>
                              Wallet Balance
                            </p>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="user-balance">
                          <Link to="/investments">
                            <div
                              className="coins"
                              style={{ color: "#00009f", fontSize: "34px" }}
                            >
                              <RiCopperCoinFill />
                            </div>
                            <p style={{ color: "#00009f", marginTop: "15px" }}>
                              Make Investment
                            </p>
                            {/* <p>45,000</p> */}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Payments section */}
                  <div className="payments">
                    <h5 style={{ color: "#00009f" }}>Receive Payments</h5>
                    <div className="row">
                      <div className="col-md-4">
                        <Link to="/">
                          <div className="dollar-section">
                            <span
                              style={{ fontSize: "50px", color: "#00009f" }}
                            >
                              <RiMoneyDollarCircleFill />
                            </span>
                            <span style={{ fontSize: "22px" }}>US Dollar</span>
                            <p>
                              Get real-time insights and secure transactions in
                              US dollars, enabling seamless global payments and
                              investments. Stay updated and in control of your
                              finances with our reliable solutions.
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-4">
                        <Link to="/">
                          <div className="pounds-section">
                            <span
                              style={{ fontSize: "50px", color: "#00009f" }}
                            >
                              <RiMoneyPoundCircleFill />
                            </span>
                            <span style={{ fontSize: "22px" }}>
                              British Pounds
                            </span>
                            <p>
                              Navigate your financial world in British Pounds
                              with ease and security. Access real-time GBP
                              insights, seamless transactions, and powerful
                              tools for smarter financial management.
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-4">
                        <Link to="/">
                          <div className="euro-section">
                            <span
                              style={{ fontSize: "50px", color: "#00009f" }}
                            >
                              <RiMoneyEuroCircleFill />
                            </span>
                            <span style={{ fontSize: "22px" }}>Euros</span>
                            <p>
                              Effortlessly manage and transact in Euros with our
                              secure, user-friendly platform. Experience
                              seamless real-time exchange rates, and robust
                              financial solutions tailored to your needs.
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="transactions table-responsive">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th scope="col">Start Date</th>
                          <th scope="col">Due Date</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Invt.Term</th>
                          <th scope="col">Invt.Return</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="modal-container">
          <div className="modal-title" >
            <h4>Select an option</h4>
          </div>
          <div className="modal-body">
            <div className="btn-creat-acct-section">
              <button className="btn-createAccount" onClick={()=>navigate("/confirm-bvn")}>
                Bank Verification Number (BVN)
              </button>
            </div>
            <div className="btn-creat-acct-section">
              <button className="btn-createAccount" onClick={()=>navigate("/confirm-nin")}>
               National Identification Number (NIN)
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default InvestorDashboard;
