import { Link } from "react-router-dom";
//import logo from "../assets/images/logo_rockbank1.png";

import "../sass/footer.scss";

import { MdLocalPhone, MdEmail, MdLocationPin } from "react-icons/md";
import { BsLinkedin, BsTwitter, BsInstagram } from "react-icons/bs";
import { MdFacebook } from "react-icons/md";

function Footer() {
  return (
    <div className="footer" id="footer-section">
      <div className="main">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h5>Contact</h5>
              <ul>
                <li>
                  <span>
                    <MdLocalPhone />
                  </span>
                  +2347067590229, +2347038392068
                </li>
                <li>
                  <span>
                    <MdEmail />
                  </span>
                  Rockglobal@africanimpactinitiative.com
                </li>
                <li>
                  <span>
                    <MdLocationPin />
                  </span>
                  No. 5 Maxwell Adoki Street Odili Road Port Harcourt Rivers
                  State.
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h5>Quick Links</h5>
              <ul>
                <li>
                  <Link to="/about"> About Us</Link>
                </li>
                <li>
                  <Link to="/projects">Contact us</Link>
                </li>
                {/* <li>
                  <Link to="/impact">Impact</Link>
                </li>
                <li>
                  <Link to="/">Partnerships</Link>
                </li> */}
              </ul>
            </div>
            <div className="col-md-2">
              <h5>Social Media</h5>
              <ul>
                <li>
                  <Link
                    to="https://www.linkedin.com/groups/9867089"
                    target="_blank"
                  >
                    <span>
                      <BsLinkedin />
                    </span>
                    LinkedIn
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <span>
                      <BsTwitter />
                    </span>
                    Twitter
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/myrockbank?igsh=MTJ2YzgzaGRtY2xhMQ=="
                    target="_blank"
                  >
                    <span>
                      <BsInstagram />
                    </span>{" "}
                    Instagram
                  </Link>
                </li>
                <li>
                  <Link>
                    <span>
                      <MdFacebook />{" "}
                    </span>
                    Facebook
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="sub-section">
            <p>
              Experience the difference with RockBank - Your partner in
              financial success!!!
            </p>
          </div>
        </div>
      </div>
      <div className="copyright">
        <span>Rockbank &copy; 2024. All rights reserved</span>
      </div>
    </div>
  );
}

export default Footer;
