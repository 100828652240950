import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import "../sass/investment.scss";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import background1 from "../assets/videos/background1.mp4";

function Investments() {
const [investmentTerm, setInvestmentTerm] = useState("");
  const { phoneNumber, accountNumber } = useContext(AuthContext);
  const [amount, setAmount] = useState("");
  const [naration, setNaration] = useState("");
  const [pageLoading, setPageLoading] = useState(false);

  const navigate = useNavigate();

  const data = {
    accountNumber: "0243831506", //Source accountNumber
    amount,
    naration,
    investmentTerm,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPageLoading(true);

    try {
      //
      const response = await axios.post(
        "https://rockbank-server.vercel.app/investments",
        data
      );

      if(response.status === 200){
        alert(response.data.message)
        navigate("/investor")
      }
  
    } catch (error) {
      console.log(error);
    } finally {
      setPageLoading(false);
    }
  };
  return (
    <div className="background-video-container">
      <video autoPlay loop muted playsInline className="background-video">
        <source src={background1} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content-overlay">
        <div className="container">
          <div className="form-section">
            <form className="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="otp" className="form-label">
                      Amount
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="otp"
                      onChange={(e) => setAmount(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="course" className="form-label">
                      Investmant Term
                    </label>
                    <select
                      className="form-select"
                      name="course"
                      aria-label="Default select "
                      onChange={(e) => setInvestmentTerm(e.target.value)}
                    >
                      <option>Select</option>
                      <option>6 months</option>
                      <option>9 months</option>
                      <option>12 months</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="otp" className="form-label">
                      Naration
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="otp"
                      onChange={(e) => setNaration(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="btn-section">
                  <button type="submit" className="btn-submit">
                    {pageLoading ? (
                      <RotatingLines
                        visible={true}
                        height="30"
                        width="30"
                        color="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    ) : (
                      <span>Submit</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Investments;
