import partner1 from "../assets/images/ifc-logo.png";
import partner2 from "../assets/images/wema.webp";
import partner3 from "../assets/images/flutterwave-logo.png";
const partners = [
  {
    name: "IFC World Bank",
    logo: partner1,
    url: "https://www.ifc.org/en/home",
  },
  {
    name: "Providus Bank",
    logo: partner2,
    url: "https://providusbank.com/",
  },
  {
    name: "Flutterwave",
    logo: partner3,
    url: "/https://flutterwave.com/ng/",
  },
];

export default partners;
