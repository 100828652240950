import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { MdArrowForward } from "react-icons/md";
import "../sass/nin.scss";
import axios from "axios";
//  import { ToastContainer, toast } from "react-toastify";
//  import "react-toastify/dist/ReactToastify.css";

const SendBVN = () => {
  const { email, phoneNumber, setTrackingId } = useContext(AuthContext);

  const [nin, setNin] = useState("");
  const [pageLoading, setPageLoading] = useState(false);

  const navigate = useNavigate();

  const data = {
    phoneNumber,
    email,
    nin,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPageLoading(true);
    //"https://rockbank-server.vercel.app/confirm-nin"

    try {
      const res = await axios.post(
        "https://rockbank-server.vercel.app/confirm-bvn",
        data
      );
      console.log(res);
      if (res.status === 200) {
        setTrackingId(res.data.data.trackingId);
        alert(res.data.message);
        //Redirect to otp page
        navigate("/confirm-otp");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <div className="main-container">
      <div className="register-content">
        <div className="reg-form-section">
          <form className="form" >
            {/* <p>Enter Your National Identification Number</p> */}
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="nin" className="form-label">
                    Enter your Bank Verifcation Number (BVN)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nin"
                    onChange={(e) => setNin(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="btn-section">
              <button type="submit" className="btn-submit">
                {pageLoading ? (
                  <RotatingLines
                    visible={true}
                    height="30"
                    width="30"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                ) : (
                  <span>
                    Submit <MdArrowForward />
                  </span>
                )}
              </button>
            </div>
          </form>
          {/* <ToastContainer /> */}
        </div>
      </div>
    </div>
  );
};

export default SendBVN;
