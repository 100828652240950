import "../sass/partners.scss";
import partners from "./partners";
import { Link } from "react-router-dom";

function OurPartners() {
  return (
    <div className="container partner-main" id="partners-section">
      <div className="partner-title">
        <h2>Our Partners</h2>
      </div>
      <div className="partners">
        <div className="row">
          {partners.map((item, i) => {
            return (
              <div className="col-md-4" key={i}>
                <Link to={item.url} target="_blank">
                  <img src={item.logo} alt={item.name} />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OurPartners;
