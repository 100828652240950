import "../sass/faqs.scss";
import { Tabs, Tab } from "react-bootstrap";
import {Link} from "react-router-dom"
import {useEffect} from "react"

function FAQs() {
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])
  return (
    <div className="faq-main">
      <div className="container">
        <div className="faq-header">
          <h2>FREQUENTLY ASKED QUESTIONS (FAQ)</h2>
        </div>
        <div className="faqs">
          <Tabs defaultActiveKey="#1" className="mb-3">
            <Tab eventKey="#1" title="">
              <div className="collapse-section">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse1"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>What is RockBank?</h6>
                  </button>
                </p>
                <div className="collapse" id="collapse1">
                  <div className=" pp">
                    <div>
                      <p>
                        RockBank is a neobank which is set to create a platform
                        that allows users earn better from their saving, helping
                        them hedge it against inflation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-section">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse2"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>Who is the founder of RockBank?</h6>
                  </button>
                </p>
                <div className="collapse" id="collapse2">
                  <div className=" pp">
                    <div>
                      {/* <p>
                        RockBank is a vision birthed by a visionary leader name
                        Sarah Zion. She is a seasoned professional who is a
                        Chattered financial and Investment analyst and holds a
                        masters degree in management. She has managed at middle
                        and top management positions across various top
                        organizations enabling significant growth in their
                        revenue. She previously co-founded and built Phast, a
                        payment platform in 2022 which grew significantly
                        gaining both local and significant recognition till date
                        registering it’s presence in the United Kingdom before
                        deciding to build RockBank based on the feedbacks she
                        got from the high market demand for this. She is
                        currently being backed by the IFC WorldBank Group on
                        this project as a winner of the SheWinsAfrica Program.
                      </p> */}
                      <p>
                        RockBank is a vision birthed by a visionary leader named
                        Sarah Zion. She is a seasoned professional who is a
                        Chattered financial and Investment analyst and holds a
                        masters degree in management. She has managed at middle
                        and top management positions across various top
                        organizations enabling significant growth in their
                        revenue. She previously co-founded and built Phast, a
                        payment platform in 2022 which grew significantly
                        gaining both local and significant recognition till date
                        registering it’s presence in the United Kingdom before
                        deciding to build RockBank based on her personal
                        experience and the feedbacks she got from the high
                        market demand for this. She is currently being backed by
                        the IFC WorldBank Group on this project as a winner of
                        the SheWinsAfrica Program. She was also a winner of the
                        Central Bank of Nigeria Venture Award.
                      </p>
                      <Link
                        to="https://www.linkedin.com/in/sarah-zion-a66791123"
                        target="_blank"
                      >
                        You can view her linkedin profile for more details.
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-section">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse3"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>Is RockBank registered?</h6>
                  </button>
                </p>
                <div className="collapse" id="collapse3">
                  <div className=" pp">
                    <div>
                      <p>
                        RockBank is a brand name of Rock Global Business Concept
                        (hereinafter called the ‘Company’) which is licensed as
                        a multi-purpose corporative society and duly registered
                        with the Corporate affairs Commission of the Federal
                        Republic of Nigeria. Reg No.: 7377468
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-section">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse4"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>When was RockBank founded?</h6>
                  </button>
                </p>
                <div className="collapse" id="collapse4">
                  <div className=" pp">
                    <div>
                      <p>
                        RockBank was founded In February 2024 after co founding
                        Phast in 2022. However, the founder is being solidly
                        backed by the IFC WorldBank Group which was founded
                        since 1944. It has a solid internal structure.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-section">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse5"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>Why Rock Discrete plan?</h6>
                  </button>
                </p>
                <div className="collapse" id="collapse5">
                  <div className=" pp">
                    <div>
                      <p>
                        While building a solution that suits the retail sector
                        to help them significantly grow their savings, a
                        decision was reached by the team to start with the
                        corporate sector which consisted of the High Networth
                        Individuals who had the interest in growing their funds
                        significantly. It became essential to build an arm of
                        the business to cater to these highly important
                        individuals who can afford to invest more to earn a
                        better yield on their investment while creating a
                        significant wealth portfolio for them through our
                        expertise.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-section">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse6"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>Who can invest in Rock Discrete plan?</h6>
                  </button>
                </p>
                <div className="collapse" id="collapse6">
                  <div className=" pp">
                    <div>
                      <p>
                        Individuals who are involved in legal business
                        activities and active earnings and can afford a minimum
                        of N5 million to invest.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-section">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse7"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>Where do we invest the funds?</h6>
                  </button>
                </p>
                <div className="collapse" id="collapse7">
                  <div className=" pp">
                    <div>
                      <p>
                        We have a team of very dedicated and highly intelligent
                        wealth managers who employ their risk management skills
                        and expertise to invest directly into the financial
                        markets for higher yield.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-section">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse8"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>Why do we give high yield?</h6>
                  </button>
                </p>
                <div className="collapse" id="collapse8">
                  <div className=" pp">
                    <div>
                      {/* <p>
                        Unlike others, We dissolve the bottleneck involved in
                        the process of investing through multiple investment
                        management firms and directly invest in the markets by
                        ourselves to gain a higher yield, from which we can
                        afford to give more to our customers.
                      </p> */}
                      <p>
                        Unlike others, We dissolve the bottleneck involved in
                        the process of investing through multiple investment
                        management firms to gain a higher yield, from which we
                        can afford to give more to our customers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-section">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse9"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>How safe is my money invested?</h6>
                  </button>
                </p>
                <div className="collapse" id="collapse9">
                  <div className=" pp">
                    <div>
                      <p>
                        Every form of investments has a risk factor attached to
                        it. Be it Money Market, FGN savings Bonds, Mutual funds,
                        Stocks and the rest. Some more risky than others.
                        However, we are very heavy on investment capital
                        protection to ensure that your invested funds stays safe
                        at all times. We have developed a formidable structure
                        both internally and externally providing various layers
                        of safety to protect our clients funds. We are very
                        dedicated towards consistently adopting risk management
                        strategies to ensure the safety of these funds at all
                        times irrespective of the occasional market dynamics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-section0">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse10"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>Does RockBank have a license?</h6>
                  </button>
                </p>
                <div className="collapse" id="collapse10">
                  <div className=" pp">
                    <div>
                      <p>
                        Yes we do. As a savings platform, we currently posses a
                        multipurpose corporative licence that allows us save
                        funds and invest them professionally for our clients.
                        However, as a neobank fully operating as a digital Bank,
                        we are not required to have a licence to operate as we
                        currently partner with other traditional banks like Wema
                        bank, Providus Bank and fintechs like Flutterwave to
                        provide our services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-section1">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse11"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>Is RockBank listed on the Nigerian stock exchange?</h6>
                  </button>
                </p>
                <div className="collapse" id="collapse11">
                  <div className=" pp">
                    <div>
                      {/* <p>
                        No. Because we aren’t a publicly listed company but a
                        private company. However, we have partners who are
                        listed on the NSE market.
                      </p> */}
                      <p>
                        At the board, we are a group of Certified SEC sponsored
                        members who are licenced as Capital market Consultants.
                        As a company, we operate in partnership with a CBN
                        approved traditional bank and a SEC approved Investment
                        Bank.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-section2">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse12"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>Do we have a physical location?</h6>
                  </button>
                </p>
                <div className="collapse" id="collapse12">
                  <div className=" pp">
                    <div>
                      <p>
                        As a Neobank, we aren’t required to have one. However
                        due to the current demand of our trusted clients whose
                        best interests we are committed to serving, we have
                        decided to start our physical headquarters in Port
                        Harcourt Rivers State Nigeria before expanding to other
                        regions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-section3">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse13"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>Do we have a Board?</h6>
                  </button>
                </p>
                <div className="collapse" id="collapse13">
                  <div className=" pp">
                    <div>
                      <p>
                        Yes we do. We have a very solid board of highly profiled
                        individuals who are committed to growing this vision
                        with us. We have connections from the WorldBank Group,
                        Central Bank of Nigeria and the African Development
                        Bank.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-section4">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse14"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>Where is our website?</h6>
                  </button>
                </p>
                <div className="collapse" id="collapse14">
                  <div className=" pp">
                    <div>
                      <p>
                        <Link to="https://rockbanking.com" target="_blank">
                          www.rockbanking.com
                        </Link>
                        {/* . However, this is set to serve the general public on
                        our retail plan. Our Discrete plan Uses a simple Google
                        doc form to allow ease and simplicity of the sign up
                        process for our HNIs clients */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-section5">
                <p>
                  <button
                    className="btn-collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse15"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h6>
                      Why should I trust RockBank despite the previous stories
                      we have seen from others in the past?
                    </h6>
                  </button>
                </p>
                <div className="collapse" id="collapse15">
                  <div className=" pp">
                    <div>
                      <p>
                        Trust is eminent at this point and we truly understand
                        this. But we’ll give you a reason to trust us and let
                        you take your decision. Every great business had 3
                        reasons to succeed and we believe we pass those 3 acid
                        tests amongst others:
                      </p>
                      <ul>
                        <li>
                          <b>Team:</b> We have a Strong, very united and high
                          performing team of dedicated members who uphold
                          integrity to the core. The CEO has over a decade of
                          professional experience in the industry, the COO has
                          over 15 years of professional experience, the CTO is a
                          senior software engineer who has a great track record
                          and the rest of the team bring in various levels of
                          expertise and experience to make this dream work. We
                          strongly believe in the synergistic effect of
                          collaboration to make this vision work well.
                        </li>
                        <li>
                          <b>Vision:</b> Our Vision is To become the foremost
                          digital bank around the world that truly cares for our
                          clients prosperity; known for our competence,
                          credibility, compassion and commitment towards their
                          best interest. This is a bank with a heart.
                        </li>
                        <li>
                          <b>Experience:</b> We have a combined teams years of
                          experience that spans over 50 years. Our Board is made
                          up of significant Individuals who can provide credible
                          backing for this vision on the tables where it
                          matters. Our Founder is well grounded in the financial
                          technology space and has gained some significant
                          recognition as the Central Bank of Nigeria Venture
                          Award winner ‘14 and a winner of the SheWinsAfrica
                          Program backed by IFC WorldBank Group ’24. She has
                          managed large fund portfolios for the organisations
                          she has worked with in the past and is great at what
                          she does. Also we have diligently researched the
                          reasons why other great companies like Blackberry, MBA
                          forex and the likes failed in the past and have duly
                          adopted Strategic Risk Mitigation measures to curb
                          that. We leverage on the knowledge gained from closely
                          studying successful industry giants like Apple,
                          Moniepoint and Flutterwave to build a structure that
                          can withstand the industry dynamics, whether political
                          or others. We are very prepared for this journey and
                          we believe we will be successful beyond any reasonable
                          doubts.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <h4>
                We believe we have started a movement that empowers people
                differently and we hope you can come on this journey with us.
                Thank you.
              </h4>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default FAQs;
