import React from "react";
import "../../sass/floatingCTAButton.scss"; // Import the CSS file

const FloatingCTAButton = ({ label, onClick }) => {
  return (
    <button className="floating-cta" onClick={onClick}>
      {label}
    </button>
  );
};

export default FloatingCTAButton;
