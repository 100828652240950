import { useRef, useContext } from "react";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import logo from "../assets/images/rock_bank2.jpg";

import "../sass/navbar.scss";

function Navbar() {
  const { authData, logout } = useContext(AuthContext);
  const navButton = useRef(null);

  const navigate = useNavigate();

  const handleScroll = () => {
    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="container">
        <div className="brand-section">
          <Link to="/">
            <img
              src={logo}
              alt="brand"
              style={{ width: "60px", borderRadius: "4px" }}
            />
          </Link>
        </div>
        <button
          ref={navButton}
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbartoggle"
          aria-controls="navbartoggle"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse nav-items" id="navbartoggle">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                onClick={handleScroll}
                className="nav-link"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                Home
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="whoarewe-section"
                className="nav-link"
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="services-section"
                className="nav-link"
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                Our Services
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="partners-section"
                className="nav-link"
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                Our Partners
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="contact-section"
                className="nav-link"
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                Contact Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={() => navigate("/faqs")}
                className="nav-link"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                FAQs
              </Link>
            </li>
          </ul>
          <div className="button-section">
            {authData.isAuthenticated ? (
              <div className="row">
                <div className="col-md-6 btn-secure">
                  <button
                    className="btn-dashboard"
                    onClick={() => navigate("/investor")}
                  >
                    Dashboard
                  </button>
                </div>
                <div className="col-md-6 btn-secure">
                  <button className="btn-logout" onClick={logout}>
                    Logout
                  </button>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-6 btn-secure">
                  <button
                    className="btn-dashboard"
                    onClick={() => navigate("/register")}
                  >
                    Register
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    className="btn-login"
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
