import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import {  useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { MdArrowForward } from "react-icons/md";

import "../sass/referral.scss";

const SignupReferral = () => {
  const { signup } = useContext(AuthContext);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    gender: "",
    referal: "",
    password: "",
    role: "Investor",
  });
  const [error, setError] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  // Form validation logic
  const validate = () => {
    let newErrors = [];

    // Name validation
    if (!form.name.trim()) {
      newErrors.name = "Name is required.";
    }

    // Email validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!form.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!emailPattern.test(form.email)) {
      newErrors.email = "Email is not valid.";
    }

    // Password validation
    if (!form.password) {
      newErrors.password = "Password is required.";
    } else if (form.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long.";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPageLoading(true);
    const formErrors = validate();

    if (Object.keys(formErrors).length === 0) {
      try {
        //call sign-up function from AuthContext
        await signup(form);

        //Redirect or update UI as needed
        navigate("/investor");
      } catch (errMsg) {
        setError(errMsg);
        setPageLoading(false);
      } finally {
        setPageLoading(false);
      }
    } else {
      // If there are errors, update the state with error messages
      setError(formErrors);
    }
  };

  return (
    <div className="plan-container">
      <div className="container">
        <div className="row">
          <div className="col-md-6 caption">
            <h1>Turn Connections into Rewards! </h1>
            <p>
              Refer your friends and earn bonuses with every successful
              investment. The more you refer, the more you earn. Start sharing
              today, it's easy, rewarding, and fun!!
            </p>
            {/* <p>Start sharing today, it's easy, rewarding, and fun!!</p> */}
          </div>
          <div className="col-md-6">
            <div className="plan-form-section">
              <form className="form" >
                <p style={{ color: "red" }}>{error}</p>

                <div className="mb-3">
                  <label htmlFor="fullName" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                  />
                  {error.name && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {error.name}
                    </p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={form.email}
                    onChange={handleChange}
                  />
                  {error.email && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {error.email}
                    </p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="PhoneNumber" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="PhoneNumber"
                    name="phoneNumber"
                    value={form.PhoneNumber}
                    onChange={handleChange}
                  />
                  {error.phonenumber && (
                    <p style={{ color: "red" }}>{error.phoneNumber}</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="ConfirmPassword" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="ConfirmPassword"
                  />
                </div>

                <div className="btn-section">
                  <button type="submit" className="btn-submit">
                    {pageLoading ? (
                      <RotatingLines
                        visible={true}
                        height="30"
                        width="30"
                        color="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    ) : (
                      <span>
                        Submit <MdArrowForward />
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupReferral;
